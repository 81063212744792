import React from "react";
import close from "../Assets/Close-icon.svg";
import "./WaitlistAlreadyMemberMessage.css";
import gif from "../Assets/hooray.json";
import Lottie from "react-lottie";
import { Dialog, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WaitlistAlreadyMemberMessage({ onClose }) {


  const handleClose = () => {
    onClose();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: gif,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          backgroundColor: "#121212",
          maxWidth: "754px",
        },
      }}
    >
      <div className={`alreadyMember `}>
        <div className="close-icon" onClick={handleClose}>
          <img src={close} alt="" id="img" />
        </div>
        <div className="gif">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
        <div className="messageText1">
          You're already a Hyprcom Founder's Circle member! 🌟
        </div>
        <div className="messageText2">
          Email address you trying to register is already in our database. Enjoy
          the benefits of early access, special privileges, and a front-row seat
          to the future of online shopping.
        </div>
      </div>
    </Dialog>
  );
}
