import Property1Default2 from "../Components/Property1Default2";
import Property1Default1 from "../Components/Property1Default1";
import Property1Default from "../Components/Property1Default";
import RevolutionaryShoppingSection from "../Components/RevolutionaryShoppingSection";
import PartnerSection from "../Components/PartnerSection";
import LifestyleCard from "../Components/LifestyleCard";
import StayInTheLoopSection1 from "../Components/StayInTheLoopSection1";
import BottomFooter from "../Components/BottomFooter";
import FAQContainer from "../Components/FAQContainer";
import Navbar from "../Components/Navbar";
import VideoComponent from "../Components/Video-component";
import React, { useState, useEffect, useRef } from "react";
import Banner_pic from "../Assets/Banner_pic.png";
import "./PreLaunchWebsiteDesign.css";
import WaitlistForm from "../Components/WaitlistForm";
import BrandForm from "../Components/BrandForm";
import WaitlistAlreadyMemberMessage from "../Components/WaitlistAlreadyMemberMessage";
import WaitlistSuccessfulMessage from "../Components/WaitlistSuccessfulMessage";
import BrandFormSubmissionMessage from "../Components/BrandFormSubmissionMessage";
import { Helmet } from "react-helmet";

const PreLaunchWebsiteDesign = () => {
  const [isNavigationActive, setIsNavigationActive] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 720);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isBrandFormOpen, setIsBrandFormOpen] = useState(false);
  const [isAlreadyMember, setIsAlreadyMember] = useState(false);
  const [isSuccessfulMember, setIsSuccessfulMember] = useState(false);
  const [isBrandAdded, setIsBrandAdded] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    const isWaitlistFormDisplayed = sessionStorage.getItem("waitlist");

    if (!isWaitlistFormDisplayed) {
      const waitlistTimer = setTimeout(() => {
        setIsFormOpen(true); 
        sessionStorage.setItem("waitlist", "true");
      }, 3000); // Open the waitlist form after 3 seconds
      
      return () => clearTimeout(waitlistTimer); // Cleanup the timer on component unmount
    }
  }, []);

  const toggleBlur = () => {
    setShowNavbar((prevShowNavbar) => !prevShowNavbar);
    setIsNavigationActive(
      (prevIsNavigationActive) => !prevIsNavigationActive && !isFormOpen
    );
  };

  // Function to scroll to BottomFooter
  const scrollToBottomFooter = () => {
    const footerElement = document.querySelector(".frame-container");
    if (footerElement) {
      window.scrollTo({
        top: footerElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isBrandAdded) {
      setIsBrandFormOpen(false);
    }

    if (isAlreadyMember) {
      setIsFormOpen(false);
    }

    if (isSuccessfulMember) {
      setIsFormOpen(false);
    }

    if (isBrandFormOpen && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isFormOpen && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isAlreadyMember && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isSuccessfulMember && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isBrandAdded && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (
      isFormOpen ||
      isBrandFormOpen ||
      isBrandAdded ||
      isSuccessfulMember ||
      isAlreadyMember
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 720);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    isFormOpen,
    isBrandFormOpen,
    isAlreadyMember,
    isSuccessfulMember,
    isBrandAdded,
    showNavbar,
  ]);

  return (
    <div
      className={`pre-launch-website-design ${
        isBrandFormOpen ? "form-open" : ""
      }  ${isFormOpen ? "form-open" : ""} `}
      ref={formRef}
    >
      <Helmet>
        <title>
          Hyprcom: Revolutionizing Fashion E-Commerce for Brands and Shoppers
        </title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Hyprcom is a unique mobile e-commerce marketplace connecting top-quality fashion brands with discerning buyers. Discover exclusive deals, sustainable products, and personalized shopping experiences."
        />
        <meta
          name="keywords"
          content="mobile e-commerce, fashion marketplace, brand partnerships, sustainable fashion, exclusive deals, personalized shopping, quality fashion brands, direct-to-consumer shopping"
        />
        <meta
          property="og:title"
          content="Hyprcom: Revolutionizing Fashion E-Commerce for Brands and Shoppers"
        />
        <meta
          property="og:description"
          content="Hyprcom is a unique mobile e-commerce marketplace connecting top-quality fashion brands with discerning buyers. Discover exclusive deals, sustainable products, and personalized shopping experiences."
        />
        <meta property="og:url" content="https://hyprcom.in" />
        <meta
          name="twitter:title"
          content="Hyprcom: Revolutionizing Fashion E-Commerce for Brands and Shoppers"
        />
        <meta
          name="twitter:description"
          content="Hyprcom is a unique mobile e-commerce marketplace connecting top-quality fashion brands with discerning buyers. Discover exclusive deals, sustainable products, and personalized shopping experiences."
        />
        <link rel="canonical" href="https://hyprcom.in/" />
      </Helmet>
      {isFormOpen && (
        <WaitlistForm
          onClose={() => setIsFormOpen(false)}
          onAlreadyMember={() => setIsAlreadyMember(true)}
          onSuccessfulyMember={() => setIsSuccessfulMember(true)}
        />
      )}
      {isBrandFormOpen && (
        <BrandForm
          onClose={() => setIsBrandFormOpen(false)}
          onBrandAdded={() => setIsBrandAdded(true)}
        />
      )}
      {isAlreadyMember && (
        <WaitlistAlreadyMemberMessage
          onClose={() => setIsAlreadyMember(false)}
        />
      )}
      {isSuccessfulMember && (
        <WaitlistSuccessfulMessage
          onFollowSocialMedia={scrollToBottomFooter}
          onClose={() => setIsSuccessfulMember(false)}
        />
      )}
      {isBrandAdded && (
        <BrandFormSubmissionMessage onClose={() => setIsBrandAdded(false)} />
      )}
      <div
        className={`forNav1  ${isFormOpen ? "form-open" : ""} ${
          showNavbar ? "active" : ""
        } ${isBrandFormOpen ? "form-open" : ""} ${
          isAlreadyMember ? "form-open" : ""
        } ${isSuccessfulMember ? "form-open" : ""}  ${
          isBrandAdded ? "form-open" : ""
        }`}
      >
        <div className="firstPage">
          <div className={`navigation-bar ${showNavbar ? "active" : ""}`}>
            <Navbar
              className="nav"
              toggleBlur={toggleBlur}
              isSmallScreen={isSmallScreen}
              setIsFormOpen={setIsFormOpen}
            />
          </div>
          <div
            className={`firstPageFlex ${isNavigationActive ? "blur" : ""} ${
              isFormOpen ? "form-open" : ""
            } `}
          >
            <div className="frame-parent">
              <div className="frame-group">
                <div className="experience-the-digital-mall-parent">
                  <div className="experience-the-digital">
                    EXPERIENCE THE DIGITAL MALL
                  </div>
                  <div className="get-ready-for-container">
                    <span className="get-ready-for">{`Get ready for `}</span>
                    <span className="fashion-upgrade">Fashion Upgrade!</span>
                  </div>
                </div>
                <div className="discover-the-future">
                  Discover the future of personalized, sustainable shopping.
                  Hyprcom brings a revolutionary platform where style meets
                  consciousness. The power of customization in your hands.
                </div>
              </div>
              <div className="launching-on-june-2024-parent">
                <Property1Default2 property1DefaultPosition="relative" />
                <div className="primary-button-parent">
                  <Property1Default1 setIsFormOpen={setIsFormOpen} />
                  <Property1Default setIsBrandFormOpen={setIsBrandFormOpen} />
                </div>
              </div>
            </div>
            <div className="iphone-14-pro-mockup">
              <img className="main-icon" alt="" src={Banner_pic} />
            </div>
          </div>
        </div>
        <div className={`video-container ${isNavigationActive ? "blur" : ""}`}>
          <VideoComponent />
        </div>
        <div className={`thirdPage ${isNavigationActive ? "blur" : ""}`}>
          <RevolutionaryShoppingSection setIsFormOpen={setIsFormOpen} />
        </div>
        <div className={`fourthPage ${isNavigationActive ? "blur" : ""}`}>
          <LifestyleCard />
        </div>
        <div className={`fifthPage ${isNavigationActive ? "blur" : ""}`}>
          <PartnerSection setIsBrandFormOpen={setIsBrandFormOpen} />
        </div>
        <div className={`faq ${isNavigationActive ? "blur" : ""}`}>
          <FAQContainer />
        </div>
        <div className={`frame-container ${isNavigationActive ? "blur" : ""}`}>
          <StayInTheLoopSection1 />
          <BottomFooter />
        </div>
      </div>
    </div>
  );
};

export default PreLaunchWebsiteDesign;
