import "./FormContainer.css";

const FormContainer = ({
  sectionTitle,
  verificationIconSectionSu,
  featureDescription,
  brandDescription,
  checkIconUrl,
  sectionHeading,
  brandImageUrl,
  verificationIconUrl,
  brandDescription1,
  communityDescription,
}) => {
  return (
    <div className="frame-parent6">
      <div className="whom-were-looking-for-wrapper">
        <div className="whom-were-looking">{sectionTitle}</div>
      </div>
      <div className="verified-check-svgrepo-com-1-container">
        <img
          className="verified-check-svgrepo-com-1-icon2"
          alt=""
          src={verificationIconSectionSu}
        />
        <div className="innovative-visionaries-parent">
          <div className="innovative-visionaries">{featureDescription}</div>
          <div className="we-seek-brands">{brandDescription}</div>
        </div>
      </div>
      <div className="verified-check-svgrepo-com-1-container">
        <img
          className="verified-check-svgrepo-com-1-icon2"
          alt=""
          src={checkIconUrl}
        />
        <div className="innovative-visionaries-parent">
          <div className="innovative-visionaries">{sectionHeading}</div>
          <div className="we-seek-brands">{brandImageUrl}</div>
        </div>
      </div>
      <div className="verified-check-svgrepo-com-1-container">
        <img
          className="verified-check-svgrepo-com-1-icon2"
          alt=""
          src={verificationIconUrl}
        />
        <div className="innovative-visionaries-parent">
          <div className="innovative-visionaries">{brandDescription1}</div>
          <div className="we-seek-brands">{communityDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
