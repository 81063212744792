import React, {useState} from "react";
import close from "../Assets/Close-icon.svg";
import { NavLink } from "react-router-dom";
import "./BrandFormSubmissionMessage.css";

export default function BrandFormMessage({onClose}) {
  const [formOpen, setFormOpen] = useState(false);

  const handleClose = () => {
    setFormOpen(false);
    onClose();
  };

  return (
    <div className={`brandFormMeassage  ${formOpen ? "formOpen" : ""}`}>
      <NavLink to="" className="closeicon" onClick={handleClose}>
        <img src={close} alt="" id="img" />
      </NavLink>
      <div className="brandMessageText1">Thank You for Choosing Hyprcom for Your Brand!</div>
      <div className="brandMessageText2">We appreciate your interest in joining Hyprcom. Our team will review your inquiry, and we'll be in touch soon to discuss how we can collaborate to bring your brand to our dynamic platform. Stay tuned for exciting possibilities!</div>
    </div>
  );
}
