import "./WaitlistSuccessfulMessage.css";
import React from "react";
import { Dialog, Slide } from "@mui/material";
import Lottie from "react-lottie";
import gif from "../Assets/welcomeAnimation.json";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WaitlistSuccessfulMessage({
  onFollowSocialMedia,
  onClose,
}) {
  const handleFollowSocialMedia = () => {
    if (onFollowSocialMedia) {
      onFollowSocialMedia();
    }

    if (onClose) {
      onClose();
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: gif,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            backgroundColor: '#121212',
            maxWidth: '754px'
          },
        }}
      >
        <div className={`WaitlistSuccessfulMessage`}>
          <div className="gif">
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
          <div className="msgText1">
            Welcome to the Hyprcom Founder's Circle! 🌟
          </div>
          <div className="msgText2">
            Follow us on social media for the latest updates, and exclusive
            insights super fast. Your journey with Hyprcom starts now!
          </div>
          <div
            className="followSocialMediaBtn"
            onClick={handleFollowSocialMedia}
          >
            <div to="" id="followSMText">
              Follow Us on Social Media
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
