import React, { useState } from "react";
import WaitlistForm from "./WaitlistForm";
import "./Property1Default1.css";
import { NavLink } from "react-router-dom";

const Property1Default1 = ({setIsFormOpen}) => {

  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  const handleShowWaitlistForm = () => {
    setIsFormOpen(true);
  };


  return (
    <NavLink className="nav-style">
      <div className="property-1default1" onClick={handleShowWaitlistForm}>
        <b className="get-notified">GET NOTIFIED</b>
      </div>
      {showWaitlistForm && (
        <WaitlistForm
          onClose={() => setShowWaitlistForm(false)}
          setIsFormOpen={false}
        />
      )}
    </NavLink>
  );
};

export default Property1Default1;
