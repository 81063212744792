import "./LifestyleCard.css";

const LifestyleCard = () => {
  return (
    <div className="hyprcom-is-not-just-a-platform-parent">
      <div className="hyprcom-is-not-container">
        <span className="hyprcom">Hyprcom</span>
        <span className="is-not-just">{` is not just a platform; it's a `}</span>
        <span className="lifestyle">lifestyle</span>
      </div>
      <div className="frame-parent10">
        <div className="curated-stores-parent">
          <div className="curated-stores">Curated Stores</div>
          <div className="explore-curated-stores">
            Explore curated stores that resonate with your lifestyle. Hyprcom
            brings together brands that share your values, creating a seamless
            and inspiring shopping environment
          </div>
        </div>
        <div className="curated-stores-parent">
          <div className="curated-stores">Elevated Shopping Experience</div>
          <div className="explore-curated-stores">
            Hyprcom goes beyond traditional shopping. Elevate your experience
            with a platform designed to cater to your preferences, delivering an
            unmatched journey in every click
          </div>
        </div>
        <div className="curated-stores-parent">
          <div className="curated-stores">Future of Online Shopping</div>
          <div className="explore-curated-stores">
            Embrace a space where innovation, style, and individuality converge
            to redefine how you shop
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifestyleCard;
