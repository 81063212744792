import "./FAQContainer.css";
import React, { useState } from "react";
import activeArrowIcon from '../Assets/Active-icon.svg';
import inactiveArrowIcon from '../Assets/Inactive-icon.svg';

const FAQContainer = () => {
  const [showUserQuestions, setShowUserQuestions] = useState(true);
  const [userSelectedIndex, setUserSelectedIndex] = useState(null);
  const [brandSelectedIndex, setBrandSelectedIndex] = useState(null);

  const toggleUserQuestions = () => {
    setShowUserQuestions(true);
    setUserSelectedIndex(null);
    setBrandSelectedIndex(null);
  };

  const toggleBrandQuestions = () => {
    setShowUserQuestions(false);
    setUserSelectedIndex(null);
    setBrandSelectedIndex(null);
  };

  const handleUserQuestionClick = (index) => {
    if (userSelectedIndex === index) {
      setUserSelectedIndex(null);
    } else {
      setUserSelectedIndex(index);
    }
  };

  const handleBrandQuestionClick = (index) => {
    if (brandSelectedIndex === index) {
      setBrandSelectedIndex(null);
    } else {
      setBrandSelectedIndex(index);
    }
  };

  const userQuestions = [
    {
      question:
        "What sets Hyprcom apart from traditional online shopping platforms?",
      answer:
        "Hyprcom redefines the shopping experience by offering a unique blend of personalized curation, community engagement, and innovative features.  Unlike traditional platforms, we focus on creating a vibrant space where users and brands converge to tell stories through fashion.",
    },
    {
      question:
        "How does Hyprcom enhance personalization compared to traditional shopping sites?",
      answer:
        "Hyprcom employs advanced algorithms to understand individual preferences, providing users with highly personalized product recommendations. This goes beyond the generic suggestions seen in traditional online shopping, ensuring a tailored experience for each user.",
    },
    {
      question:
        "How does Hyprcom contribute to sustainability, a feature not commonly associated with traditional e-commerce?",
      answer:
        "Hyprcom is committed to sustainability by onboarding brands that prioritize eco-friendly practices. This focus on conscious consumption sets us apart from traditional platforms that may not place as much emphasis on the environmental impact of their products.",
    },
    {
      question:
        "What is the Founder's Circle Badge, and how can I become part of it?",
      answer:
        "The Founder's Circle Badge is an exclusive honor for early users. To become part of it, simply join Hyprcom early, enjoy the privileges, and be recognized as a valued member shaping the platform's early days.",
    },
  ];

  const brandQuestions = [
    {
      question: "How can I customize my brand's storefront on Hyprcom?",
      answer:
        "Easily customize your storefront via the Brand Dashboard, tailoring visuals and product displays to align seamlessly with your brand's identity...",
    },
    {
      question: "What type of brands are you looking for on Hyprcom?",
      answer:
        "We welcome innovative, Quality Craftsmanship, and Long-lasting products.  Whether you're a startup or an established brand, if you align with these values, Hyprcom is the perfect platform for you.",
    },
    {
      question:
        "In what ways does Hyprcom's Future Vision differ from the trajectory of traditional online shopping platforms?",
      answer:
        "Hyprcom's Future Vision involves integrating technology and fashion seamlessly, offering users an immersive and innovative experience. This forward-thinking approach contrasts with traditional platforms that may not prioritize such cutting-edge features.",
    },
    {
      question:
        "How does Hyprcom contribute to the success of brands in comparison to traditional e-commerce partnerships?",
      answer:
        "Hyprcom provides brands with customizable stores, a direct connection to a community of engaged shoppers, and opportunities for personalized marketing campaigns. This level of brand control and collaboration goes beyond the standardized approach seen in traditional e-commerce partnerships.",
    },
  ];

  return (
    <div id="faq">
      <div className="faq-title">
        <div className="faq-text">Frequently Asked Questions</div>
      </div>
      <div className="toggle-section">
        <div
          className={
            showUserQuestions ? "toggle-button active-button" : "toggle-button"
          }
          onClick={toggleUserQuestions}
        >
          For Users
        </div>
        <div
          className={
            !showUserQuestions ? "toggle-button active-button" : "toggle-button"
          }
          onClick={toggleBrandQuestions}
        >
          For Brands
        </div>
      </div>
      {showUserQuestions ? (
        <div className="for-user">
          {userQuestions.map((item, index) => (
            <div className={userSelectedIndex === index ? 'question active' : 'question'} key={index}>
              <div
                className="inline"
                onClick={() => handleUserQuestionClick(index)}
              >
                <p>{item.question}</p>
                <img src={userSelectedIndex === index ? activeArrowIcon : inactiveArrowIcon} alt="Icon" className={userSelectedIndex === index ? "icon active" : "icon"} />
              </div>
              <div
                className={
                  userSelectedIndex === index ? "answer visible" : "answer"
                }
              >
                {userSelectedIndex === index && item.answer}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="for-brand">
          {brandQuestions.map((item, index) => (
            <div className={brandSelectedIndex === index ? 'question active' : 'question'} key={index}>
              <div
                className="inline"
                onClick={() => handleBrandQuestionClick(index)}
              >
                <p>{item.question}</p>
                <img
                  src={brandSelectedIndex === index ? activeArrowIcon : inactiveArrowIcon}
                  alt="Icon"
                  className={
                    brandSelectedIndex === index ? "icon active" : "icon"
                  }
                />
              </div>
              <div
                className={
                  brandSelectedIndex === index ? "answer visible" : "answer"
                }
              >
                {brandSelectedIndex === index && item.answer}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FAQContainer;
