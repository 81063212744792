import React, { useState } from "react";
import close from "../Assets/Close-icon.svg";
import LoadingModal from "./LoadingModal";
import "./WaitlistForm.css";

const WaitlistForm = ({ onClose, onAlreadyMember, onSuccessfulyMember }) => {
  const [formOpen, setFormOpen] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setFormOpen(false);
    onClose();
  };

  function handleJoinWaitlist(e) {
    const formEle = document.querySelector("form");
    e.preventDefault();
    const formData = new FormData(formEle);

    const name = formData.get("Name");
    const email = formData.get("Email");
    const gender = formData.get("Gender");
    const age = formData.get("Age");

    if (!name || !email || !gender || !age) {
      setFormMessage("Please fill out all fields");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormMessage("Please enter a valid Email");
      return;
    }

    setIsLoading(true);

    const apiURL = "https://script.google.com/macros/s/AKfycbzwRjT4h09MJlcv1Fz7zFdaCwGxY8_oPXVmKz-547GX9rPpqpWjiuNS0u28T_Dc-lTf4w/exec";
    fetch(apiURL, { method: "POST", body: formData })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((result) => {
        if (result.includes("Email already exists")) {
          setIsLoading(false);
          setFormMessage("Email already exists in the waitlist");
          onAlreadyMember();
        } else if (
          result.includes("You have been added to the Waitlist successfully")
        ) {
          setIsLoading(false);
          setFormMessage("Successfully joined the waitlist!");
          onSuccessfulyMember();
        } else {
          setIsLoading(false);
          setFormMessage("Unknown response from server");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error joining the waitlist:", error);
        setFormMessage("Error joining the waitlist. Please try again later.");
      });
  }

  return (
    <div className={`waitlistForm ${formOpen ? "formOpen" : ""}`}>
      {" "}
      <div className="closeicon" onClick={handleClose}>
        <img src={close} alt="" id="img" />
      </div>
      <div className="textWrapper1">
        Become a pioneer in the evolution of online shopping
      </div>
      <div className="textWrapper2">
        Join the waitlist for exclusive perks and early access. Your journey
        with Hyprcom begins here
      </div>
      <form className="innerForm">
        <input type="hidden" name="FormType" value="WaitlistForm" />
        <input
          type="text"
          name="Name"
          className="name"
          placeholder="Your Name"
          autoComplete="name"
          required
        />
        <input
          type="email"
          name="Email"
          id="email"
          placeholder="Email Address"
          autoComplete="email"
          required
        />
        <select
          type="text"
          name="Gender"
          className="gender"
          autoComplete="sex"
          defaultValue=""
          required
        >
          <option value="" disabled>
            Select Gender
          </option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>

        <input
          type="age"
          name="Age"
          className="age"
          placeholder="Age"
          autoComplete="bday-year"
          required
        />
        <div
          className="joinWaitlistButton"
          type="submit"
          onClick={(e) => handleJoinWaitlist(e)}
        >
          <div className="joinWaitlistText">
            Join the Waitlist and Secure Your Spot
          </div>
        </div>
      </form>
      <div className="formMessage">{formMessage}</div>
      {isLoading && <LoadingModal />}
    </div>
  );
};

export default WaitlistForm;
