import "./TermsPage.css";
import icon from "../Assets/Hyprcom-icon.svg";
import gradient from "../Assets/Background.svg";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <div className="termsPage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and Conditions</title>
        <meta property="og:title" content="Terms and Conditions" />
        <meta
          property="og:url"
          content="https://hyprcom.in/termsandconditions"
        />
        <meta name="twitter:title" content="Terms and Conditions" />
      </Helmet>
      <NavLink to="/">
        <img src={icon} alt="" className="privacyPageIcon" />
      </NavLink>
      <img src={gradient} alt="" className="forGradient" />
      <div className="termsPageContents">
        <div className="privacyTitle">
          <span className="innerPrivacy1">{`Terms and Conditions `}</span>
          <span className="innerPrivacy2">of Use for Hyprcom</span>
        </div>
        <div className="TermsContents">
          <div className="privacyContent">
            <div className="title1">1. Acceptance of Terms:</div>
            <div className="title2">
              By accessing and using the Hyprcom platform ("Hyprcom"), you agree
              to comply with and be bound by these Terms and Conditions. Your
              use of Hyprcom constitutes your acceptance of these terms. If you
              do not agree to these terms, please refrain from using Hyprcom.
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">2.Use of Hyprcom</div>
            <div className="title2">
              <div className="innerTerms">
                <span className="boldSpan">{`2.1 Eligibility: `}</span>You must
                be at least 18 years old or have the legal capacity to enter
                into contracts to use Hyprcom. You agree to use Hyprcom for
                lawful purposes and in accordance with these Terms and
                Conditions.
              </div>
              <div className="innerTerms">
                <span className="boldSpan">{`2.2 User Accounts: `}</span>To
                access certain features of Hyprcom, you may be required to
                create an account. You are responsible for maintaining the
                confidentiality of your account information and for all
                activities that occur under your account.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">3. User Conduct:</div>
              <div className="title2">You agree not to:</div>
            </div>
            <div className="innerContent">
              <div className="innerPoints">
                Violate any applicable laws or regulations.
              </div>
              <div className="innerPoints">
                Infringe on the rights of others.
              </div>
              <div className="innerPoints">
                Use Hyprcom for any fraudulent or unlawful purpose.
              </div>
              <div className="innerPoints">
                Post or transmit any content that is harmful, offensive, or
                violates the rights of others.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">4. Intellectual Property:</div>
            <div className="title2">
              All content on Hyprcom, including but not limited to text,
              graphics, logos, images, and software, is the property of Hyprcom
              or its licensors and is protected by intellectual property laws
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">5. Third-Party Links:</div>
            <div className="title2">
              Hyprcom may contain links to third-party websites. These links are
              provided for your convenience, and Hyprcom is not responsible for
              the content of linked websites.
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">6. Product Listings and Purchases:</div>
            <div className="title2">
              <div className="innerTerms">
                <span className="boldSpan">{`6.1 Product Representations: `}</span>
                Hyprcom facilitates transactions between buyers and sellers.
                Sellers are responsible for accurately representing their
                products, and buyers are responsible for reviewing product
                details before making a purchase.
              </div>
              <div className="innerTerms">
                <span className="boldSpan">{`6.2 Transactions: `}</span>
                Transactions conducted on Hyprcom are subject to additional
                terms outlined in our Purchase and Sale Agreemen
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">7. Privacy:</div>
            <div className="title2">
              The collection and use of personal information on Hyprcom are
              governed by our Privacy Policy. By using Hyprcom, you consent to
              the practices outlined in the Privacy Policy
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">8. Disclaimers:</div>
            <div className="title2">
              <div className="innerTerms">
                <span className="boldSpan">{`8.1 Platform Availability: `}</span>
                Hyprcom is provided on an "as is" and "as available" basis. We
                make no warranties, express or implied, regarding the accuracy,
                completeness, or availability of the platform.
              </div>
              <div className="innerTerms">
                <span className="boldSpan">{`8.2 Content Accuracy: `}</span>
                While we strive to provide accurate information, we do not
                guarantee the accuracy of content on Hyprcom, including product
                descriptions, pricing, and availability.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">9. Limitation of Liability:</div>
            <div className="title2">
              To the extent permitted by law, Hyprcom and its affiliates,
              officers, directors, and employees will not be liable for any
              indirect, consequential, or incidental damages arising out of or
              in connection with the use of Hyprcom.
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">10. Changes to Terms:</div>
            <div className="title2">
              We reserve the right to update or modify these Terms and
              Conditions at any time. Changes will be effective immediately upon
              posting. Please review these terms regularly
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">11. Termination:</div>
            <div className="title2">
              We reserve the right to terminate or suspend your access to
              Hyprcom at our discretion, without notice, for any reason,
              including violation of these Terms and Conditions.
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">12. Governing Law:</div>
            <div className="title2">
              These Terms and Conditions are governed by and construed in
              accordance with the laws of [insert jurisdiction]. Any disputes
              arising under or in connection with these terms shall be subject
              to the exclusive jurisdiction of the courts in [insert
              jurisdiction]
            </div>
          </div>
          <div className="privacyContent">
            <div className="title1">13. Contact Information:</div>
            <div className="title2">
              For inquiries related to these Terms and Conditions, please
              contact us at [insert contact email or form link].
            </div>
          </div>
          <div className="privacyContent">
            Thank you for using Hyprcom responsibly.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
