import "./BrandForm.css";
import React, { useState, useEffect } from "react";
import close from "../Assets/Close-icon.svg";
import Multiselect from "./Multiselect";
import LoadingModal from "./LoadingModal";

const BrandForm = ({ isBrandFormOpen, onClose, onBrandAdded }) => {
  const [brandFormOpen, setBrandFormOpen] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [optionSelected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (selected) => {
    setSelected(selected);
  };

  const handleClose = () => {
    setBrandFormOpen(false);
    onClose();
  };

  const options = [
    { value: "Clothing", label: "Clothing" },
    { value: "Beauty and Personal Care", label: "Beauty and Personal Care" },
    { value: "Footwares", label: "Footwares" },
    { value: "Kids Wear", label: "Kids Wear" },
    { value: "Fashion Accesories", valabellue: "Fashion Accesories" },
    { value: "Handbags and Accessories", label: "Handbags and Accessories" },
    { value: "Jewelry and watches", label: "Jewelry and watches" },
    { value: "Sustainable Fashion", label: "Sustainable Fashion" },
  ];

  async function handleBrandSubmission(e) {
    e.preventDefault();
    setIsLoading(true);
    const formEle = document.querySelector("form");
    const formData = new FormData(formEle);

    const brandName = formData.get("BrandName");
    const contactPerson = formData.get("ContactPerson");
    const mobileNumber = formData.get("MobileNumber");
    const email = formData.get("Email");
    const description = formData.get("Description");
    const website = formData.get("Website");
    const selectedCategoriesValue = optionSelected.some(
      (option) => option.label === "Select All"
    )
      ? options
          .filter((option) => option.label !== "Select All")
          .map((option) => option.label)
          .join(", ")
      : optionSelected.map((option) => option.label).join(", ");
    console.log(
      brandName,
      contactPerson,
      mobileNumber,
      email,
      selectedCategoriesValue,
      website,
      description
    );

    formData.set("Category", selectedCategoriesValue);

    // If any field is empty, display an error and prevent form submission

    if (
      !brandName ||
      !contactPerson ||
      !mobileNumber ||
      !email
    ) {
      setFormMessage("Please fill out all fields");
      return;
    }

    // Email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormMessage("Please enter a valid Email");
      return;
    }

    const apiURL = "https://script.google.com/macros/s/AKfycbzwRjT4h09MJlcv1Fz7zFdaCwGxY8_oPXVmKz-547GX9rPpqpWjiuNS0u28T_Dc-lTf4w/exec";
    fetch(apiURL, { method: "POST", body: formData })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((result) => {
        if (result.includes("Your Brand have been successfully")) {
          setIsLoading(false);
          setFormMessage("Successfully added your Brand!");
          onBrandAdded();
        } else {
          setFormMessage("Unknown response from server");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error adding Brand:", error);
        setFormMessage("Error adding Brand. Please try again later.");
      });
  }

  useEffect(() => {
    setBrandFormOpen(isBrandFormOpen);
  }, [isBrandFormOpen]);

  return (
    <div className={`frame2 ${brandFormOpen ? "formOpen" : ""}`}>
      <div className="closeicon" onClick={handleClose}>
        <img src={close} alt="" id="img" />
      </div>
      <div className="wrapper3">
        <div className="showYourBrand">
          Interested in Showcasing Your Brand on Hyprcom?
        </div>
        <div className="complete-the-form">
          Complete the form below, and let's explore how we can elevate your
          brand together. We look forward to connecting with you!
        </div>
      </div>
      <form action="" method="" className="form1">
        <div className="form">
          <input type="hidden" name="FormType" value="BrandForm" />
          <input
            type="text"
            name="BrandName"
            className="brandName"
            placeholder="Brand Name"
            autoComplete="organization"
          />
          <input
            type="text"
            name="ContactPerson"
            className="contactPerson"
            placeholder="Contact Person"
            autoComplete="name"
          />
          <input
            type="tel"
            name="MobileNumber"
            className="phoneNumber"
            placeholder="Enter phone number"
            autoComplete="tel"
          />
          <input
            type="email"
            name="Email"
            className="email"
            placeholder="Email Address"
            autoComplete="email"
          />
          <div className="state-dropdown-category">
            <Multiselect
              key="example_id"
              options={options}
              onChange={handleChange}
              value={optionSelected}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          <input
            type="url"
            name="Website"
            className="website"
            placeholder="Website (if applicable)"
            autoComplete="url"
          />
          <textarea
            name="Description"
            className="description"
            placeholder="Tell us about your Brand"
            autoComplete="off"
          ></textarea>
          <div
            type="submit"
            className="submitButton"
            onClick={(e) => handleBrandSubmission(e)}
          >
            <div className="submit">Submit</div>
          </div>
        </div>
      </form>
      <div className="formMessage">{formMessage}</div>
      {isLoading && (
        <LoadingModal />
      )}
    </div>
  );
};

export default BrandForm;
