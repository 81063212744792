import React, { useState } from "react";
import FormContainer from "./FormContainer";
import { NavLink } from "react-router-dom";
import "./PartnerSection.css";
import icon from "../Assets/verifiedchecksvgrepocom-1-1@2x.png";
import BrandForm from "./BrandForm";

const PartnerSection = ({ setIsBrandFormOpen }) => {
  const [showBrandForm, setShowBrandForm] = useState(false);

  const handleShowBrandForm = () => {
    setIsBrandFormOpen(true);
  };

  return (
    <div className="pre-launch-website-design-inner1">
      <div className="frame-parent7">
        <div className="partner-with-hyprcom-parent">
          <div className="partner-with-hyprcom-container">
            <span>Partner</span>
            <span className="with-hyprcom"> with Hyprcom</span>
          </div>
          <div className="over-25-visionary">
            <span className="join-hyprcom-today">
              {" "}
              Join Hyprcom today, start selling for free! Over 25 visionary
              brands have already chosen us to revolutionize online shopping.
              Your brand could be next!{" "}
            </span>
            <span className="fill-the-form">Fill the fom!</span>
          </div>
        </div>
        <div className="frame-parent8">
          <FormContainer
            sectionTitle="Whom we’re looking for"
            verificationIconSectionSu={icon}
            featureDescription="Innovative Visionaries"
            brandDescription="We seek brands with an innovative vision whether you're a startup or an established brand, those unafraid to challenge the status quo and redefine the boundaries of online retail"
            checkIconUrl={icon}
            sectionHeading="Quality Craftsmanship"
            brandImageUrl="Whether it's fashion, baby products, beauty and make-up essentials, bags, mobile cases we're searching for brands that take pride in delivering top-notch, quality craftsmanship"
            verificationIconUrl={icon}
            brandDescription1="Community-Centric Brands"
            communityDescription="Join our community of like-minded brands. If you value creating connections beyond transactions and fostering brand loyalty, you're exactly who we're looking for"
          />
          <FormContainer
            sectionTitle="What You Get"
            verificationIconSectionSu={icon}
            featureDescription="Limitless Customization"
            brandDescription="Craft a storefront that aligns perfectly with your brand identity. Hyprcom offers limitless customization, providing a unique space for your brand to shine"
            checkIconUrl={icon}
            sectionHeading="Engaged Community Connection:"
            brandImageUrl="Connect with a thriving community of users who appreciate and celebrate uniqueness. Your brand becomes an integral part of a larger narrative, fostering meaningful connections"
            verificationIconUrl={icon}
            brandDescription1="Strategic Growth Insights"
            communityDescription="Access real-time insights into user behavior and preferences through Hyprcom Analytics. Strategically position your brand, respond to trends, and elevate your marketing efforts"
          />
        </div>
        <div className="frame-parent9">
          <NavLink className="nav-style" onClick={handleShowBrandForm}>
            <div className="onboard-on-hyprcom-wrapper">
              <b className="onboard-on-hyprcom1">ONBOARD ON HYPRCOM</b>
            </div>
          </NavLink>
          <NavLink
            to="https://calendly.com/d/422-4xt-qv8/hyprcom-partnership-consultation?month=2024-01"
            className="nav-style"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="schedule-a-call-with-us-wrapper">
              <b className="onboard-on-hyprcom2">SCHEDULE A CALL WITH US</b>
            </div>
          </NavLink>
        </div>
      </div>
      {showBrandForm && (
        <BrandForm
          onClose={() => setShowBrandForm(false)}
          setIsBrandFormOpen={false}
        />
      )}
    </div>
  );
};

export default PartnerSection;
