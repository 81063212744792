import { useEffect } from "react";
import AboutUs from "./pages/About-Hyprcom";
import ContactUs from "./pages/ContactUs";
import PrivacyPage from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsPage";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import PreLaunchWebsiteDesign from "./pages/PreLaunchWebsiteDesign";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      default:
        // Handle default case or add further cases as needed
        break;
    }
    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);
  return (
      <Routes>
        <Route path="/" element={<PreLaunchWebsiteDesign />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacypolicy" element={<PrivacyPage />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
      </Routes>
  );
}
export default App;
