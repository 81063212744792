import React, { useState } from "react";
import ExclusiveLaunchPartyInvitation from "./ExclusiveLaunchPartyInvitation";
import { NavLink } from "react-router-dom";
import "./RevolutionaryShoppingSection.css";
import icon from "../Assets/verifiedchecksvgrepocom-1-1@2x.png";
import WaitlistForm from "./WaitlistForm";

const RevolutionaryShoppingSection = ({
  setIsFormOpen
}) => {
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  const handleShowWaitlistForm = () => {
    setIsFormOpen(true);
  };

  return (
    <div className="pre-launch-website-design-inner">
      <div className="frame-parent4">
        <div className="ready-to-witness-a-revolution-parent">
          <div className="ready-to-witness-container">
            <span>{`Ready to witness a `}</span>
            <span className="revolution-in-shopping">
              revolution in shopping?
            </span>
          </div>
          <div className="join-the-waitlist">
            Join the waitlist now for exclusive early access to Hyprcom
          </div>
        </div>
        <div className="frame-parent5">
          <ExclusiveLaunchPartyInvitation
            badgeImageUrl={icon}
            specialBadgeText="Limited Edition Hyprcom Badge"
            earlyAdopterBadgeText="Flaunt your early adopter status with a limited edition Hyprcom badge on your profile and access to new features early"
            badgeIconUrl={icon}
            exclusiveLaunchPartyInvit="Exclusive Launch Party Invitation"
            launchPartyDescription="Join our virtual launch party and be part of an exclusive community celebration"
          />
          <ExclusiveLaunchPartyInvitation
            badgeImageUrl={icon}
            specialBadgeText="Early Bird Loyalty Cash"
            earlyAdopterBadgeText="Earn bonus loyalty cash will not expired during the early access period"
            badgeIconUrl={icon}
            exclusiveLaunchPartyInvit="Founder's Circle Membership"
            launchPartyDescription={`By being among the first to join Hyprcom, you automatically become a part of an elite community with lots of benefits known as the "Founder's Circle”`}
          />
        </div>
        <NavLink className="nav-style">
          <div
            className="join-waitlist-container"
            onClick={handleShowWaitlistForm}
          >
            <b className="join-waitlist">JOIN WAITLIST</b>
          </div>
        </NavLink>
      </div>
      {showWaitlistForm && (
        <WaitlistForm
          onClose={() => setShowWaitlistForm(false)}
          setIsFormOpen={false}
        />
      )}
    </div>
  );
};

export default RevolutionaryShoppingSection;
