import { NavLink } from "react-router-dom";
import "./StayInTheLoopSection1.css";
import x_icon from "../Assets/X-icon.svg";
import facebook_icon from "../Assets/Facebook-icon.svg";
import LinkedIn_icon from "../Assets/LinkedIn-icon.svg";
import insta_icon from "../Assets/Instagram_icon.svg";
import youtube_icon from "../Assets/YouTube-icon.jpeg";

const StayInTheLoopSection1 = () => {
  return (
    <div className="frame-wrapper">
      <div className="frame-parent11">
        <div className="stay-in-the-loop-parent">
          <div className="stay-in-the">Stay in the Loop!!</div>
          <div className="be-part-of">
            Be part of vibrant community on social media and Hyprcom Founder’s
            Circle Member. Get the latest updates, exclusive offers, and connect
            with like-minded enthusiasts.
          </div>
        </div>
        <div className="frame-parent12">
          <NavLink
            to="https://www.instagram.com/hyprcom/"
            className="nav-links"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="frame-parent13">
              <img className="frame-inner" alt="" src={insta_icon} />
              <div className="hyprcom1">/hyprcom</div>
            </div>
          </NavLink>
          <NavLink
            to="https://www.linkedin.com/company/hyprcom/"
            className="nav-links"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="frame-parent13">
              <img className="frame-inner" alt="" src={LinkedIn_icon} />
              <div className="hyprcom1">/hyprcom</div>
            </div>
          </NavLink>
          <NavLink
            to="https://m.facebook.com/profile.php/?id=61556068414964&name=xhp_nt__fb__action__open_user"
            className="nav-links"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="frame-parent13">
              <img className="frame-inner" alt="" src={facebook_icon} />
              <div className="hyprcom1">/hyprcom</div>
            </div>
          </NavLink>
          <NavLink
            to="https://twitter.com/hyprcom_in"
            className="nav-links"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="frame-parent13">
              <img className="frame-inner" alt="" src={x_icon} />
              <div className="hyprcom1">/hyprcom</div>
            </div>
          </NavLink>
          <NavLink to="" className="nav-links">
            <div className="frame-parent13">
              <img className="frame-inner" alt="" src={youtube_icon} />
              <div className="hyprcom1">/hyprcom</div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default StayInTheLoopSection1;
