import React from "react";
import "./PrivacyPolicy.css";
import icon from "../Assets/Hyprcom-icon.svg";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

const PrivacyPage = () => {
  return (
    <div className="privacyPage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
        <meta property="og:title" content="Privacy Policy" />
        <meta property="og:url" content="https://hyprcom.in/privacypolicy" />
        <meta name="twitter:title" content="Privacy Policy" />
      </Helmet>
      <NavLink to="/">
        <img src={icon} alt="" className="privacyPageIcon" />
      </NavLink>
      <div className="privacyPageContents">
        <div className="privacyTitle">
          <span className="innerPrivacy1">{`Privacy Policy  `}</span>
          <span className="innerprivacy2"> for Hyprcom</span>
        </div>
        <div className="privacyContents">
          <div className="innerContent1">
            Welcome to Hyprcom! This Privacy Policy is designed to inform you
            about how we collect, use, disclose, and safeguard your personal
            information. By using Hyprcom, you agree to the terms outlined in
            this policy.
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">1. Information Collected:</div>
              <div className="title2">
                We collect various types of personal information, including but
                not limited to:
              </div>
            </div>
            <div className="innerContent">
              <div className="innerPoints">
                Contact information (name, email, address)
              </div>
              <div className="innerPoints">Account details</div>
              <div className="innerPoints">Purchase history</div>
              <div className="innerPoints">User preferences</div>
              <div className="innerPoints">
                This information may be collected directly from users during
                account creation, transactions, or interactions with our
                platform. Additionally, we may receive data from third-party
                sources to enhance our services.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">2. How Information is Collected:</div>
              <div className="title2">
                We utilize various methods to collect information, including:
              </div>
            </div>
            <div className="innerContent">
              <div className="innerPoints">User registration forms</div>
              <div className="innerPoints">
                Cookies and tracking technologies
              </div>
              <div className="innerPoints">Analytics tools</div>
              <div className="innerPoints">
                These methods help us tailor your experience on Hyprcom,
                providing personalized services and improving our overall
                offering
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">3. Purpose of Collection:</div>
              <div className="title2">
                Your information is collected for the following purposes:
              </div>
            </div>
            <div className="innerContent">
              <div className="innerPoints">
                To provide and personalize our services
              </div>
              <div className="innerPoints">To process and fulfill orders</div>
              <div className="innerPoints">
                To improve our products and services
              </div>
              <div className="innerPoints">To communicate with users</div>
              <div className="innerPoints">
                To offer a personalized and enhanced user experience
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">4. Third-Party Sharing:</div>
              <div className="title2">
                We may share your information with trusted third parties,
                including service providers and marketing partners, to enhance
                our services and offer a more personalized experience. These
                third parties are obligated to maintain the confidentiality of
                your information.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">
                5. Cookies and Tracking Technologies:
              </div>
              <div className="title2">
                Hyprcom uses cookies and similar technologies to enhance user
                experience. You have the option to manage your cookie
                preferences through our platform settings.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">6. User Rights:</div>
              <div className="title2">You have the right to:</div>
            </div>
            <div className="innerContent">
              <div className="innerPoints">
                Access and review your personal information
              </div>
              <div className="innerPoints">
                Correct inaccuracies in your information
              </div>
              <div className="innerPoints">
                Delete your information under certain circumstances
              </div>
              <div className="innerPoints">
                Instructions on exercising these rights are provided on our
                platform.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">7. Security Measures:</div>
              <div className="title2">
                We employ robust security measures to protect your personal
                information from unauthorized access, disclosure, alteration,
                and destruction. Your information is handled with the utmost
                care to ensure its confidentiality and integrity.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">8. Data Retention:</div>
              <div className="title2">
                We retain your information for as long as necessary to fulfill
                the purposes outlined in this policy. The criteria for data
                retention are based on the nature of your interactions with
                Hyprcom.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">9. Policy Changes:</div>
              <div className="title2">
                This privacy policy may be updated to reflect changes in our
                practices or legal requirements. Users will be notified of
                significant changes, and we encourage you to review the policy
                periodically.
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">10. Contact Information:</div>
              <div className="title2">
                For privacy-related inquiries, please contact us at [insert
                contact email or form link].
              </div>
            </div>
          </div>
          <div className="privacyContent">
            <div className="titleContent">
              <div className="title1">11. Legal Compliance:</div>
              <div className="title2">
                Hyprcom is committed to complying with relevant data protection
                laws and regulations. This policy is governed by the
                jurisdiction applicable to Hyprcom.
              </div>
            </div>
            <div className="innerContent">
              <div className="innerField">
                <div className="innerPoints">
                  Thank you for entrusting Hyprcom with your information. Your
                  privacy and trust are of the utmost importance to us.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
