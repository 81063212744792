import "./ContactUs.css";
import React from "react";
import Navbar from "../Components/Navbar";
import insta_icon from "../Assets/Instagram_icon.svg";
import LinkedIn_icon from "../Assets/LinkedIn-icon.svg";
import facebook_icon from "../Assets/Facebook-icon.svg";
import x_icon from "../Assets/X-icon.svg";
import youtube_icon from "../Assets/YouTube-icon.jpeg";
import { useState, useEffect, useRef } from "react";
import WaitlistForm from "../Components/WaitlistForm";
import WaitlistAlreadyMemberMessage from "../Components/WaitlistAlreadyMemberMessage";
import WaitlistSuccessfulMessage from "../Components/WaitlistSuccessfulMessage";
import BrandFormSubmissionMessage from "../Components/BrandFormSubmissionMessage";
import Multiselect from "../Components/Multiselect";
import LoadingModal from "../Components/LoadingModal";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isSmallScreen] = useState(window.innerWidth <= 720);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isAlreadyMember, setIsAlreadyMember] = useState(false);
  const [isSuccessfulMember, setIsSuccessfulMember] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [isBrandAdded, setIsBrandAdded] = useState(false);
  const formRef = useRef(null);
  const [optionSelected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (selected) => {
    setSelected(selected);
  };

  const options = [
    { value: "Clothing", label: "Clothing" },
    { value: "Beauty and Personal Care", label: "Beauty and Personal Care" },
    { value: "Footwares", label: "Footwares" },
    { value: "Kids Wear", label: "Kids Wear" },
    { value: "Fashion Accesories", valabellue: "Fashion Accesories" },
    { value: "Handbags and Accessories", label: "Handbags and Accessories" },
    { value: "Jewelry and watches", label: "Jewelry and watches" },
    { value: "Sustainable Fashion", label: "Sustainable Fashion" },
  ];

  const toggleBlur = () => {
    setShowNavbar((prevShowNavbar) => !prevShowNavbar);
  };

  // Function to scroll to BottomFooter
  const scrollToBottomFooter = () => {
    const footerElement = document.querySelector(".socialLinks");
    if (footerElement) {
      window.scrollTo({
        top: footerElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  async function handleBrandSubmission(e) {
    e.preventDefault();
    setIsLoading(true);
    const formEle = document.querySelector("form");
    const formData = new FormData(formEle);

    const brandName = formData.get("BrandName");
    const contactPerson = formData.get("ContactPerson");
    const mobileNumber = formData.get("MobileNumber");
    const email = formData.get("Email");
    const description = formData.get("Description");
    const selectedCategoriesValue = optionSelected.some(
      (option) => option.label === "Select All"
    )
      ? options
          .filter((option) => option.label !== "Select All")
          .map((option) => option.label)
          .join(", ")
      : optionSelected.map((option) => option.label).join(", ");

    formData.set("Category", selectedCategoriesValue);

    // If any field is empty, display an error or prevent form submission
    if (
      !brandName ||
      !contactPerson ||
      !mobileNumber ||
      !email ||
      !description
    ) {
      setFormMessage("Please fill out all fields");
      return;
    }

    // Email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormMessage("Please enter a valid Email");
      return;
    }

    const apiURL = "https://script.google.com/macros/s/AKfycbzwRjT4h09MJlcv1Fz7zFdaCwGxY8_oPXVmKz-547GX9rPpqpWjiuNS0u28T_Dc-lTf4w/exec";
    fetch(apiURL, { method: "POST", body: formData })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((result) => {
        if (result.includes("Your Brand have been successfully")) {
          setIsLoading(false);
          setFormMessage("Successfully added your Brand!");
          setIsBrandAdded(true);
        } else {
          setFormMessage("Unknown response from server");
        }
      })
      .catch((error) => {
        console.error("Error adding Brand:", error);
        setFormMessage("Error adding Brand. Please try again later.");
      });
  }

  useEffect(() => {
    if (isBrandAdded) {
      setIsBrandAdded(true);
    }
    if (isAlreadyMember) {
      setIsFormOpen(false);
    }

    if (isSuccessfulMember) {
      setIsFormOpen(false);
    }

    if (isBrandAdded && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isSuccessfulMember && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (isFormOpen || isBrandAdded || isSuccessfulMember || isAlreadyMember) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isFormOpen, isAlreadyMember, isSuccessfulMember, isBrandAdded]);

  return (
    <div
      className={`contact-us ${isFormOpen ? "form-open" : ""} `}
      ref={formRef}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Contact Hyprcom: Get in Touch for Partnerships and Support
        </title>
        <meta
          name="description"
          content="Reach out to Hyprcom for queries, support, or partnership opportunities. Our contact page provides all the necessary details to connect with us, ensuring a seamless communication experience."
        />
        <meta
          name="keywords"
          content="contact Hyprcom, e-commerce support, fashion marketplace contact, brand partnership contact, customer support, e-commerce inquiries, connect with Hyprcom, business opportunities in fashion"
        />
        <meta
          property="og:title"
          content="Contact Hyprcom: Get in Touch for Partnerships and Support"
        />
        <meta
          property="og:description"
          content="Reach out to Hyprcom for queries, support, or partnership opportunities. Our contact page provides all the necessary details to connect with us, ensuring a seamless communication experience."
        />
        <meta property="og:url" content="https://hyprcom.in/contact" />
        <meta
          name="twitter:title"
          content="Contact Hyprcom: Get in Touch for Partnerships and Support"
        />
        <meta
          name="twitter:description"
          content="Reach out to Hyprcom for queries, support, or partnership opportunities. Our contact page provides all the necessary details to connect with us, ensuring a seamless communication experience."
        />
        <link rel="canonical" href="https://hyprcom.in/contact" />
      </Helmet>
      {isFormOpen && (
        <WaitlistForm
          onClose={() => setIsFormOpen(false)}
          onAlreadyMember={() => setIsAlreadyMember(true)}
          onSuccessfulyMember={() => setIsSuccessfulMember(true)}
        />
      )}
      {isSuccessfulMember && (
        <WaitlistSuccessfulMessage
          onFollowSocialMedia={scrollToBottomFooter}
          onClose={() => setIsSuccessfulMember(false)}
        />
      )}
      {isAlreadyMember && (
        <WaitlistAlreadyMemberMessage
          onClose={() => setIsAlreadyMember(false)}
        />
      )}
      {isBrandAdded && (
        <BrandFormSubmissionMessage onClose={() => setIsBrandAdded(false)} />
      )}
      <div
        className={`forNav1  ${isFormOpen ? "form-open" : ""} ${
          isAlreadyMember ? "form-open" : ""
        } ${isSuccessfulMember ? "form-open" : ""} ${
          isBrandAdded ? "form-open" : ""
        }`}
      >
        <Navbar
          toggleBlur={toggleBlur}
          isSmallScreen={isSmallScreen}
          setIsFormOpen={setIsFormOpen}
        />
        <div className={`contactGrid ${showNavbar ? "blur" : ""}`}>
          <div className="frame1">
            <div className="wrapper1">
              <span className="span1">Let’s Refine the </span>{" "}
              <span className="span2">Shopping Experience</span>
            </div>
            <div className="wrapper2">
              Calling all visionary brands! Hyprcom is on a mission to reshape
              the shopping experience. Join us in creating a personalized,
              innovative, and trendsetting platform. Elevate your brand and
              redefine online shopping with Hyprcom. Let&#39;s craft the future
              together
            </div>
          </div>
          <div className="bform">
            <div className="wrapper3">
              <div className="showYourBrand">
                Interested in Showcasing Your Brand on Hyprcom?
              </div>
              <div className="complete-the-form">
                Complete the form below, and let's explore how we can elevate
                your brand together. We look forward to connecting with you!
              </div>
            </div>
            <form action="" method="" className="contactForm1">
              <div className="contactForm">
                <input type="hidden" name="FormType" value="BrandForm" />
                <input
                  type="text"
                  name="BrandName"
                  className="contactBrandName"
                  placeholder="Brand Name"
                  autoComplete="organization"
                />
                <input
                  type="text"
                  name="ContactPerson"
                  className="contactContactPerson"
                  placeholder="Contact Person"
                  autoComplete="name"
                />
                <input
                  type="tel"
                  name="MobileNumber"
                  className="contactPhoneNumber"
                  placeholder="Enter phone number"
                  autoComplete="tel"
                />
                <input
                  type="email"
                  name="Email"
                  className="contactEmail"
                  placeholder="Email Address"
                  autoComplete="email"
                />
                <div className="contact-state-dropdown-category">
                  <Multiselect
                    key="example_id"
                    options={options}
                    onChange={handleChange}
                    value={optionSelected}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                  />
                </div>
                <input
                  type="url"
                  name="Website"
                  className="contactWebsite"
                  placeholder="Website (if applicable)"
                  autoComplete="url"
                />
                <textarea
                  name="Description"
                  className="contactDescription"
                  placeholder="Tell us about your Brand"
                  autoComplete="off"
                ></textarea>
                <div
                  type="submit"
                  className="contactSubmitButton"
                  onClick={(e) => handleBrandSubmission(e)}
                >
                  <div className="submit">Submit</div>
                </div>
              </div>
            </form>
            <div className="formMessage">{formMessage}</div>
          </div>
          <div className="frame3">
            <>
              <div className="contacts">
                <div className="visitors">
                  <div className="for">For Visitors</div>
                  <NavLink type="email" className="emails">
                    contact@hyprcom.in
                  </NavLink>
                </div>
                <div className="brands">
                  <div className="for">For Brands</div>
                  <NavLink type="email" className="emails">
                    business@hyprcom.in
                  </NavLink>
                </div>
              </div>
            </>
            <div className="stayInLoop">Stay in the loop!</div>
            <>
              <div className="socialLinks">
                <NavLink to="" className="nav-links">
                  <div className="frame-parent13">
                    <img className="frame-inner" alt="" src={insta_icon} />
                    <div className="hyprcom1">/hyprcom</div>
                  </div>
                </NavLink>
                <NavLink
                  to="https://www.linkedin.com/company/hyprcom/"
                  className="nav-links"
                >
                  <div className="frame-parent13">
                    <img className="frame-inner" alt="" src={LinkedIn_icon} />
                    <div className="hyprcom1">/hyprcom</div>
                  </div>
                </NavLink>
                <NavLink to="" className="nav-links">
                  <div className="frame-parent13">
                    <img className="frame-inner" alt="" src={facebook_icon} />
                    <div className="hyprcom1">/hyprcom</div>
                  </div>
                </NavLink>
                <NavLink to="" className="nav-links">
                  <div className="frame-parent13">
                    <img className="frame-inner" alt="" src={x_icon} />
                    <div className="hyprcom1">/hyprcom</div>
                  </div>
                </NavLink>
                <NavLink to="" className="nav-links">
                  <div className="frame-parent13">
                    <img className="frame-inner" alt="" src={youtube_icon} />
                    <div className="hyprcom1">/hyprcom</div>
                  </div>
                </NavLink>
              </div>
            </>
          </div>
          <div className="frame4">© 2024 · Hyprcom and Company</div>
        </div>
      </div>
      {isLoading && <LoadingModal />}
    </div>
  );
};

export default Contact;
