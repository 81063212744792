import React, { useRef, useEffect } from "react";
import video from '../Assets/video.mp4';
import "./Video-component.css";

const VideoComponent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null, 
      rootMargin: '0px', 
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    }, options);

    observer.observe(videoRef.current);

    return () => observer.disconnect();
  }, []); 

  return (
    <div className="video-container">
      <video ref={videoRef} width="640" height="360" loop muted controls >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
