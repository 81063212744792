import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Property1Default.css";
import BrandForm from "./BrandForm";

const Property1Default = ({setIsBrandFormOpen}) => {

  const [showBrandForm, setShowBrandForm] = useState(false);

  const handleShowBrandForm = () => {
    setIsBrandFormOpen(true);
  };

  return (
    <NavLink className="nav-partner" onClick={handleShowBrandForm}>
      <div className="property-1default" >
        <b className="become-partner">BECOME PARTNER</b>
      </div>
      {showBrandForm && (
        <BrandForm
          onClose={() => setShowBrandForm(false)}
          setIsBrandFormOpen={false}
        />
      )}
    </NavLink>
  );
};

export default Property1Default;
