import "./ExclusiveLaunchPartyInvitation.css";

const ExclusiveLaunchPartyInvitation = ({
  badgeImageUrl,
  specialBadgeText,
  earlyAdopterBadgeText,
  badgeIconUrl,
  exclusiveLaunchPartyInvit,
  launchPartyDescription,
}) => {
  return (
    <div className="frame-parent3">
      <div className="verified-check-svgrepo-com-1-parent">
        <img
          className="verified-check-svgrepo-com-1-icon"
          alt=""
          src={badgeImageUrl}
        />
        <div className="limited-edition-hyprcom-badge-parent">
          <div className="limited-edition-hyprcom">{specialBadgeText}</div>
          <div className="flaunt-your-early">{earlyAdopterBadgeText}</div>
        </div>
      </div>
      <div className="verified-check-svgrepo-com-1-parent">
        <img
          className="verified-check-svgrepo-com-1-icon"
          alt=""
          src={badgeIconUrl}
        />
        <div className="limited-edition-hyprcom-badge-parent">
          <div className="limited-edition-hyprcom">
            {exclusiveLaunchPartyInvit}
          </div>
          <div className="flaunt-your-early">{launchPartyDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveLaunchPartyInvitation;
