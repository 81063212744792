import { NavLink } from "react-router-dom";
import "./BottomFooter.css";
import hyprcom_icon from "../Assets/Hyprcom-icon.svg";

const BottomFooter = () => {
  return (
    <div className="frame-parent18">
      <div className="frame-parent19">
        <div className="privacy-policy-parent">
          <NavLink to="/privacypolicy" className="nav-links">
            <div className="privacy-policy">Privacy Policy</div>
          </NavLink>
          <NavLink to="/termsandconditions" className="nav-links">
            <div className="privacy-policy">Terms and Conditions</div>
          </NavLink>
        </div>
        <div className="hyprcomIcon">
          <NavLink to="/">
            <img className="frame-child4" alt="" src={hyprcom_icon} />
          </NavLink>
        </div>
        <div className="frame-parent20">
          <div className="privacy-policy-parent">
            <div className="for-visitors-parent">
              <div className="for-visitors">For Visitors</div>
              <a
                href="mailto:contact@hyprcom.in"
                className="contacthyprcomin"
                type="email"
              >
                contact@hyprcom.in
              </a>
            </div>
            <div className="for-visitors-parent">
              <div className="for-visitors">For Brands</div>
              <a
                href="mailto:business@hyprcom.in"
                className="contacthyprcomin"
                type="email"
              >
                business@hyprcom.in
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="hyprcomAndCompany">© 2024 · Hyprcom</div>
    </div>
  );
};

export default BottomFooter;
