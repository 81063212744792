import { useMemo } from "react";
import "./Property1Default2.css";

const Property1Default2 = ({ property1DefaultPosition }) => {
  const property1DefaultStyle = useMemo(() => {
    return {
      position: property1DefaultPosition,
    };
  }, [property1DefaultPosition]);

  return (
    <div className="property-1default2" style={property1DefaultStyle}>
      <div className="launching-soon">LAUNCHING SOON...</div>
    </div>
  );
};

export default Property1Default2;
